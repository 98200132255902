<template>
  <cover>
    <template #background>
      <img src="https://via.placeholder.com/1920x440?text=adomicilio cover" alt="Adomicilio">
    </template>
    <h1>Sucursales</h1>
  </cover>
  <div class="container-padding controls pt-2 pb-2 align-center grid-columns-3">
    <div class="empty"></div>
    <button class="mr-a ml-a">Ver Mapa</button>
    <div class="order ml-a flex align-center">
      <p class="min mb-0 mt-0 color-secondary mr-1">Ordenar</p>
      <label>
        <input type="text" placeholder="Orden Alfabético">
      </label>
    </div>
  </div>
  <div class="grid-columns-3 container-padding mb-1">
    <div class="card p-2">
      <div class="card-content">
        <div class="img">
          <img class="round-20" src="https://via.placeholder.com/480x350?text=adomicilio" alt="Adomicilio">
          <button class="btn-white-secondary" @click="setLocationInfoVisibility(true)">Ver más</button>
        </div>
        <div class="text-left full">
          <h2 class="color-primary mt-0 mb-0">
            <svg class="path-primary" width="19" height="16" viewBox="0 0 19 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.5 0H1.5V2H17.5V0ZM18.5 10V8L17.5 3H1.5L0.5 8V10H1.5V16H11.5V10H15.5V16H17.5V10H18.5ZM9.5 14H3.5V10H9.5V14Z"
                fill="#F36E44"/>
            </svg>
            Condesa
          </h2>
          <p class="alt color-secondary-on mb-0">
            Alfonso Reyes 273, Hipódromo Condesa, 06100, Miguel Hidalgo
          </p>
          <p class="alt color-secondary-on mt-0 mb-0">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.83336 4.49919H7.16669V5.83253H5.83336V4.49919ZM8.50003 4.49919H9.83336V5.83253H8.50003V4.49919ZM11.1667 4.49919H12.5V5.83253H11.1667V4.49919ZM11.32 8.67252L9.62669 8.47919C9.22003 8.43252 8.82003 8.57253 8.53336 8.85919L7.30669 10.0859C5.42003 9.12586 3.87336 7.58586 2.91336 5.69252L4.14669 4.45919C4.43336 4.17253 4.57336 3.77253 4.52669 3.36586L4.33336 1.68586C4.25336 1.01253 3.68669 0.505859 3.00669 0.505859H1.85336C1.10003 0.505859 0.47336 1.13253 0.520027 1.88586C0.87336 7.57919 5.42669 12.1259 11.1134 12.4792C11.8667 12.5259 12.4934 11.8992 12.4934 11.1459V9.99252C12.5 9.31919 11.9934 8.75252 11.32 8.67252Z"
                fill="#0D396F"/>
            </svg>
            Whatsapp: <a class="color-primary" href="tel:555 271 6285">555 271 6285</a> | Teléfono sucursal: <a
            class="color-primary" href="tel:555 271 7666">555 271 7666</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mb-5">
    <i class="color-primary pi pi-spin pi-spinner" style="font-size: 2.5rem"></i>
  </div>
  <Dialog v-model:visible="locationInfoVisibility">
    <div class="flex sm-direction-column">
      <img class="round-20" src="https://via.placeholder.com/460x460?text=adomicilio" alt="Adomicilio">
      <div class="content pl-2 pr-2 sm-mt-1">
        <h2 class="mt-0 color-secondary-on">El Califa</h2>
        <p class="color-primary">
          <svg class="path-primary mr-05" width="18" height="16" viewBox="0 0 18 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M17 0H1V2H17V0ZM18 10V8L17 3H1L0 8V10H1V16H11V10H15V16H17V10H18ZM9 14H3V10H9V14Z" fill="#F36E44"/>
          </svg>
          Condesa
        </p>
        <p class="color-secondary-on">Alfonso Reyes 273, Hipódromo Condesa, 06100, Miguel Hidalgo</p>
        <div class="grid-columns-2 color-secondary-on">
          <div class="first pr-1">
            <h5 class="mb-0">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.00028 0.167969C2.79195 0.167969 0.166945 2.79297 0.166945 6.0013C0.166945 9.20964 2.79195 11.8346 6.00028 11.8346C9.20861 11.8346 11.8336 9.20964 11.8336 6.0013C11.8336 2.79297 9.20861 0.167969 6.00028 0.167969ZM8.45028 8.4513L5.41695 6.58464V3.08464H6.29195V6.11797L8.91695 7.69297L8.45028 8.4513Z"
                  fill="#0D396F"/>
              </svg>
              Horario a domicilio
            </h5>
            <p class="mt-1 flex">
              Lu - Vi
              <span class="ml-a">12:00 - 16:00 hrs.</span>
            </p>
            <p class="flex">
              Sá
              <span class="ml-a">12:00 - 16:00 hrs.</span>
            </p>
            <p class="flex">
              Do
              <span class="ml-a">12:00 - 16:00 hrs.</span>
            </p>
          </div>
          <div class="second pr-1">
            <h5 class="mb-0">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.00028 0.167969C2.79195 0.167969 0.166945 2.79297 0.166945 6.0013C0.166945 9.20964 2.79195 11.8346 6.00028 11.8346C9.20861 11.8346 11.8336 9.20964 11.8336 6.0013C11.8336 2.79297 9.20861 0.167969 6.00028 0.167969ZM8.45028 8.4513L5.41695 6.58464V3.08464H6.29195V6.11797L8.91695 7.69297L8.45028 8.4513Z"
                  fill="#0D396F"/>
              </svg>
              Horario sucursal
            </h5>
            <p class="mt-1 flex">
              Lu - Vi
              <span class="ml-a">12:00 - 16:00 hrs.</span>
            </p>
            <p class="flex">
              Sá
              <span class="ml-a">12:00 - 16:00 hrs.</span>
            </p>
            <p class="flex">
              Do
              <span class="ml-a">12:00 - 16:00 hrs.</span>
            </p>
          </div>
        </div>
        <h5 class="color-secondary-on mb-05">
          <svg class="mr-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.33336 3.99919H6.66669V5.33253H5.33336V3.99919ZM8.00003 3.99919H9.33336V5.33253H8.00003V3.99919ZM10.6667 3.99919H12V5.33253H10.6667V3.99919ZM10.82 8.17252L9.12669 7.97919C8.72003 7.93252 8.32003 8.07253 8.03336 8.35919L6.80669 9.58586C4.92003 8.62586 3.37336 7.08586 2.41336 5.19252L3.64669 3.95919C3.93336 3.67253 4.07336 3.27253 4.02669 2.86586L3.83336 1.18586C3.75336 0.512526 3.18669 0.00585938 2.50669 0.00585938H1.35336C0.600027 0.00585938 -0.0266396 0.632526 0.0200271 1.38586C0.37336 7.07919 4.92669 11.6259 10.6134 11.9792C11.3667 12.0259 11.9934 11.3992 11.9934 10.6459V9.49252C12 8.81919 11.4934 8.25252 10.82 8.17252Z"
              fill="#0D396F"/>
          </svg>
          Contacto
        </h5>
        <p class="alt color-secondary-on mt-0 mb-0">
          Whatsapp: <a class="color-primary" href="tel:555 271 6285">555 271 6285</a> | Teléfono sucursal: <a
          class="color-primary" href="tel:555 271 7666">555 271 7666</a>
        </p>
        <p class="min sm-direction-column flex mt-2 color-secondary-on">
          <span class="flex align-center mr-2">
            <svg class="mr-05 path-primary" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.6 0C11.8 0 15.2 3.39998 15.2 7.59998C15.2 11.8 11.8 15.2 7.6 15.2C3.4 15.2 0 11.8 0 7.59998C0 3.39998 3.4 0 7.6 0ZM2.2 8.5C2.2 8.5 2.3 8.5 2.2 8.5C3.4 9.7 4.6 10.9 5.7 12.1C5.8 12.2 5.8 12.2 5.8 12.1C8.1 9.69998 10.5 7.30002 12.8 4.90002C12.8 4.90002 12.8 4.89993 12.9 4.79993C12.3 4.19993 11.7 3.6 11.1 3C9.3 4.8 7.5 6.7 5.8 8.5C5.2 7.9 4.6 7.29995 4 6.69995C3.4 7.29995 2.8 7.9 2.2 8.5Z"
                fill="#F36E44"/>
            </svg>
            Entrega a domicilio
          </span>
          <span class="flex align-center mr-2">
            <svg class="mr-05 path-primary" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.6 0C11.8 0 15.2 3.39998 15.2 7.59998C15.2 11.8 11.8 15.2 7.6 15.2C3.4 15.2 0 11.8 0 7.59998C0 3.39998 3.4 0 7.6 0ZM2.2 8.5C2.2 8.5 2.3 8.5 2.2 8.5C3.4 9.7 4.6 10.9 5.7 12.1C5.8 12.2 5.8 12.2 5.8 12.1C8.1 9.69998 10.5 7.30002 12.8 4.90002C12.8 4.90002 12.8 4.89993 12.9 4.79993C12.3 4.19993 11.7 3.6 11.1 3C9.3 4.8 7.5 6.7 5.8 8.5C5.2 7.9 4.6 7.29995 4 6.69995C3.4 7.29995 2.8 7.9 2.2 8.5Z"
                fill="#F36E44"/>
            </svg>
            Pickup
          </span>
          <span class="flex align-center mr-2">
            <svg class="mr-05 path-primary" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.6 0C11.8 0 15.2 3.39998 15.2 7.59998C15.2 11.8 11.8 15.2 7.6 15.2C3.4 15.2 0 11.8 0 7.59998C0 3.39998 3.4 0 7.6 0ZM2.2 8.5C2.2 8.5 2.3 8.5 2.2 8.5C3.4 9.7 4.6 10.9 5.7 12.1C5.8 12.2 5.8 12.2 5.8 12.1C8.1 9.69998 10.5 7.30002 12.8 4.90002C12.8 4.90002 12.8 4.89993 12.9 4.79993C12.3 4.19993 11.7 3.6 11.1 3C9.3 4.8 7.5 6.7 5.8 8.5C5.2 7.9 4.6 7.29995 4 6.69995C3.4 7.29995 2.8 7.9 2.2 8.5Z"
                fill="#F36E44"/>
            </svg>
            Salón
          </span>
          <span class="flex align-center mr-2">
            <svg class="mr-05 path-primary" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.6 0C11.8 0 15.2 3.39998 15.2 7.59998C15.2 11.8 11.8 15.2 7.6 15.2C3.4 15.2 0 11.8 0 7.59998C0 3.39998 3.4 0 7.6 0ZM2.2 8.5C2.2 8.5 2.3 8.5 2.2 8.5C3.4 9.7 4.6 10.9 5.7 12.1C5.8 12.2 5.8 12.2 5.8 12.1C8.1 9.69998 10.5 7.30002 12.8 4.90002C12.8 4.90002 12.8 4.89993 12.9 4.79993C12.3 4.19993 11.7 3.6 11.1 3C9.3 4.8 7.5 6.7 5.8 8.5C5.2 7.9 4.6 7.29995 4 6.69995C3.4 7.29995 2.8 7.9 2.2 8.5Z"
                fill="#F36E44"/>
            </svg>
            Pagos Digitales
          </span>
        </p>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Cover from '@/components/Cover'
import Dialog from 'primevue/dialog'

export default {
  components: {
    Cover,
    Dialog
  },
  data () {
    return {
      locationInfoVisibility: false
    }
  },
  methods: {
    setLocationInfoVisibility (val = true) {
      this.locationInfoVisibility = val
    }
  }
}
</script>
