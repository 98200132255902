<template>
  <div class="component-header color-00">
    <slot name="background">
      <img src="https://via.placeholder.com/1920x440?text=adomicilio" alt="Adomicilio">
    </slot>
    <div class="wrapper">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Cover'
}
</script>
