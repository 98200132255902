<template>
  <section class="grid-columns-2 login pr-5">
    <img v-if="signInStep === 0" src="https://via.placeholder.com/880x800?text=adomicilio" class="image-cover"
         alt="Adomicilio">
    <img v-if="signInStep === 1" src="https://via.placeholder.com/880x1200?text=adomicilio" class="image-cover"
         alt="Adomicilio">
    <div v-if="signInStep === 0" class="content pt-2">
      <h2 class="color-secondary">Regístrate</h2>
      <p class="mb-2 alt mt-0">¡Registrate con cualquiera de las siguientes opciones!</p>
      <div class="options flex direction-column mt-3">
        <button class=" mb-2 btn-apple">
          <svg class="mr-1" width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.8734 9.03593C11.8734 7.60449 12.4936 6.62038 13.7341 5.8152C13.0253 4.83109 11.962 4.20484 10.5443 4.11538C9.21518 4.02591 7.79742 4.92055 7.17717 4.92055C6.64553 4.92055 5.31646 4.20485 4.34178 4.20485C2.2152 4.20485 0 5.90466 0 9.21485C0 10.199 0.177193 11.2725 0.531622 12.2567C0.974659 13.6881 2.74681 17.0877 4.51895 16.9983C5.49363 16.9983 6.11389 16.2826 7.3544 16.2826C8.50629 16.2826 9.12655 16.9983 10.1898 16.9983C12.0506 16.9983 13.557 13.867 14 12.4356C11.7848 11.362 11.8734 9.03593 11.8734 9.03593ZM9.74681 2.77341C10.8101 1.5209 10.7215 0.447323 10.6329 0C9.74681 0.0894646 8.6835 0.626252 8.06324 1.34197C7.44299 2.05769 6.99998 3.0418 7.08859 4.02591C8.15187 4.11537 9.03795 3.66805 9.74681 2.77341Z"
              fill="white"/>
          </svg>
          Apple ID
        </button>
        <button class=" mb-2 btn-facebook">
          <svg class="mr-1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 8.04467C0 12.022 2.88867 15.3293 6.66667 16V10.222H4.66667V8H6.66667V6.222C6.66667 4.222 7.95533 3.11133 9.778 3.11133C10.3553 3.11133 10.978 3.2 11.5553 3.28867V5.33333H10.5333C9.55533 5.33333 9.33333 5.822 9.33333 6.44467V8H11.4667L11.1113 10.222H9.33333V16C13.1113 15.3293 16 12.0227 16 8.04467C16 3.62 12.4 0 8 0C3.6 0 0 3.62 0 8.04467Z"
                  fill="white"/>
          </svg>
          Facebook
        </button>
        <button class=" mb-2 btn-google">
          <svg class="mr-1" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.5 0C3.80517 0 0 3.80588 0 8.5C0 13.1941 3.80517 17 8.5 17C13.1941 17 17 13.1941 17 8.5C17 3.80588 13.1941 0 8.5 0ZM8.59917 13.4711C5.85933 13.4711 3.64083 11.2469 3.64083 8.5C3.64083 5.75308 5.85933 3.52892 8.59917 3.52892C9.93792 3.52892 11.0571 4.02262 11.9156 4.82446L10.5173 6.22554V6.22271C9.99671 5.72546 9.33654 5.47046 8.59917 5.47046C6.96292 5.47046 5.63337 6.85596 5.63337 8.49717C5.63337 10.137 6.96292 11.5267 8.59917 11.5267C10.0838 11.5267 11.0939 10.6753 11.3022 9.50654H8.59917V7.56783H13.2635C13.3259 7.90075 13.3592 8.24783 13.3592 8.61192C13.3592 11.4523 11.463 13.4711 8.59917 13.4711Z"
                  fill="white"/>
          </svg>
          Google
        </button>
        <button @click="signInStep++" class="mb-1">
          <svg class="mr-1" width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.1667 0.833008H1.83334C1.05417 0.833008 0.423755 1.47051 0.423755 2.24967L0.416672 10.7497C0.416672 11.5288 1.05417 12.1663 1.83334 12.1663H13.1667C13.9458 12.1663 14.5833 11.5288 14.5833 10.7497V2.24967C14.5833 1.47051 13.9458 0.833008 13.1667 0.833008ZM12.8833 3.84342L7.87542 6.97426C7.64876 7.11592 7.35126 7.11592 7.12459 6.97426L2.11667 3.84342C1.93959 3.73009 1.83334 3.53884 1.83334 3.33342C1.83334 2.85884 2.35042 2.57551 2.75417 2.82342L7.50001 5.79134L12.2458 2.82342C12.6496 2.57551 13.1667 2.85884 13.1667 3.33342C13.1667 3.53884 13.0604 3.73009 12.8833 3.84342Z"
              fill="white"/>
          </svg>
          Correo Electrónico
        </button>
      </div>
      <p class="alt">Al registrarte estas aceptando los Términos y Condiciones y la Política de Privacidad.</p>
    </div>
    <form class="color-90" v-if="signInStep === 1">
      <button class="color-40 btn-clean" @click="signInStep = 0">
        <i class="pi pi-chevron-left"></i>
        <u class="ml-05">Regresar</u>
      </button>
      <h1 class="color-secondary">Regístrate con tu correo</h1>
      <h5 class="mb-0">Nombre</h5>
      <label class="icon-left">
        <input type="email" placeholder="Ingresa tu Nombre">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 6C7.6575 6 9 4.6575 9 3C9 1.3425 7.6575 0 6 0C4.3425 0 3 1.3425 3 3C3 4.6575 4.3425 6 6 6ZM6 7.5C3.9975 7.5 0 8.505 0 10.5V11.25C0 11.6625 0.3375 12 0.75 12H11.25C11.6625 12 12 11.6625 12 11.25V10.5C12 8.505 8.0025 7.5 6 7.5Z"
            fill="#747474"/>
        </svg>
      </label>
      <h5 class="mb-0">Apellido</h5>
      <label class="icon-left">
        <input type="email" placeholder="Ingresa tu Apellido">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 6C7.6575 6 9 4.6575 9 3C9 1.3425 7.6575 0 6 0C4.3425 0 3 1.3425 3 3C3 4.6575 4.3425 6 6 6ZM6 7.5C3.9975 7.5 0 8.505 0 10.5V11.25C0 11.6625 0.3375 12 0.75 12H11.25C11.6625 12 12 11.6625 12 11.25V10.5C12 8.505 8.0025 7.5 6 7.5Z"
            fill="#747474"/>
        </svg>
      </label>
      <h5 class="mb-0">Correo Electrónico</h5>
      <label class="icon-left">
        <input type="email" placeholder="Ingresa tu Correo Electrónico">
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.1667 0.333008H1.83341C1.05425 0.333008 0.423831 0.970508 0.423831 1.74967L0.416748 10.2497C0.416748 11.0288 1.05425 11.6663 1.83341 11.6663H13.1667C13.9459 11.6663 14.5834 11.0288 14.5834 10.2497V1.74967C14.5834 0.970508 13.9459 0.333008 13.1667 0.333008ZM12.8834 3.34342L7.8755 6.47426C7.64883 6.61592 7.35133 6.61592 7.12467 6.47426L2.11675 3.34342C1.93966 3.23009 1.83341 3.03884 1.83341 2.83342C1.83341 2.35884 2.3505 2.07551 2.75425 2.32342L7.50008 5.29134L12.2459 2.32342C12.6497 2.07551 13.1667 2.35884 13.1667 2.83342C13.1667 3.03884 13.0605 3.23009 12.8834 3.34342Z"
            fill="#747474"/>
        </svg>
      </label>
      <h5 class="mb-0">Contraseña</h5>
      <label class="icon-left">
        <input type="email" placeholder="Ingresa tu Contraseña">
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7502 5.16634H10.0418V3.74967C10.0418 1.79467 8.45516 0.208008 6.50016 0.208008C4.54516 0.208008 2.9585 1.79467 2.9585 3.74967V5.16634H2.25016C1.471 5.16634 0.833496 5.80384 0.833496 6.58301V13.6663C0.833496 14.4455 1.471 15.083 2.25016 15.083H10.7502C11.5293 15.083 12.1668 14.4455 12.1668 13.6663V6.58301C12.1668 5.80384 11.5293 5.16634 10.7502 5.16634ZM4.30433 3.74967C4.30433 2.53842 5.28891 1.55384 6.50016 1.55384C7.71141 1.55384 8.696 2.53842 8.696 3.74967V5.16634H4.30433V3.74967Z"
            fill="#747474"/>
        </svg>
      </label>
      <h5 class="mb-0">Confirmar Contraseña</h5>
      <label class="icon-left">
        <input type="email" placeholder="Confirma tu Contraseña">
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7502 5.16634H10.0418V3.74967C10.0418 1.79467 8.45516 0.208008 6.50016 0.208008C4.54516 0.208008 2.9585 1.79467 2.9585 3.74967V5.16634H2.25016C1.471 5.16634 0.833496 5.80384 0.833496 6.58301V13.6663C0.833496 14.4455 1.471 15.083 2.25016 15.083H10.7502C11.5293 15.083 12.1668 14.4455 12.1668 13.6663V6.58301C12.1668 5.80384 11.5293 5.16634 10.7502 5.16634ZM4.30433 3.74967C4.30433 2.53842 5.28891 1.55384 6.50016 1.55384C7.71141 1.55384 8.696 2.53842 8.696 3.74967V5.16634H4.30433V3.74967Z"
            fill="#747474"/>
        </svg>

      </label>
      <p class="mt-1 flex justify-space-between">
        <label class="flex direction-row align-center alt mb-2 alt color-40">
          <Checkbox class="mr-1" name="op1" v-model="privacy" :binary="true"/>
          <span>Acepto las Políticas de Privacidad</span>
        </label>
      </p>
      <button class="mt-1" @click="signInDialog()">Registrarme</button>
    </form>
  </section>
  <user-data-dialogs ref="userDataDialogs"/>
</template>
<script>
import Checkbox from 'primevue/checkbox'
import UserDataDialogs from '@/components/UserDataDialogs'
export default {
  data () {
    return {
      signInStep: 0,
      privacy: false
    }
  },
  components: {
    UserDataDialogs,
    Checkbox
  },
  methods: {
    signInDialog () {
      this.$refs.userDataDialogs.setUserDataDialogsVisibility(true)
    }
  }
}
</script>
<style lang="scss">
.login {
  max-width: 1680px;

  .content {
    p.alt {
      max-width: 420px;
    }
  }
}

.options {
  max-width: 220px;

  .btn-apple {
    background-color: $neutral90 !important;
  }

  .btn-facebook {
    background-color: #1877F2 !important;
  }

  .btn-google {
    background-color: #EA4335 !important;
  }
}
</style>
